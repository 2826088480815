import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import VueAnalytics from "vue-analytics";
import "leaflet/dist/leaflet.css";
import "leaflet.locatecontrol/src/L.Control.Locate";
import "leaflet.locatecontrol/dist/L.Control.Locate.css";

import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

Vue.use(VueAnalytics, {
  id: "UA-177053221-1",
  router,
  debug: {
    sendHitTask: process.env.NODE_ENV === "production",
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
