import Vue from "vue";
import Vuex from "vuex";
import { latLng } from "leaflet";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    center:
      localStorage.getItem("center") !== null
        ? latLng(JSON.parse(localStorage.getItem("center")))
        : latLng(52.132633, 5.291266),
    zoom:
      localStorage.getItem("zoom") !== null
        ? parseInt(localStorage.getItem("zoom"))
        : 11,
    tileLayer:
      localStorage.getItem("tileLayer") !== null
        ? localStorage.getItem("tileLayer")
        : "Kaart",
    pinRestrictedAreas:
      localStorage.getItem("pinRestrictedAreas") !== null
        ? JSON.parse(localStorage.getItem("pinRestrictedAreas"))
        : false,
    showRestrictedAreas: false,
  },
  mutations: {
    center(state, val) {
      localStorage.setItem("center", JSON.stringify(val));
      state.center = val;
    },
    zoom(state, val) {
      localStorage.setItem("zoom", JSON.stringify(val));
      state.zoom = val;
    },
    tileLayer(state, name) {
      localStorage.setItem("tileLayer", name);
      state.tileLayer = name;
    },
    pinRestrictedAreas(state, val) {
      localStorage.setItem("pinRestrictedAreas", val);
      state.pinRestrictedAreas = val;
    },
    showRestrictedAreas(state, val) {
      state.showRestrictedAreas = val;
    },
  },
  actions: {},
  modules: {},
});
