<template>
  <v-app id="piepkaart" dark>
    <v-navigation-drawer width="270" v-model="drawer" clipped app>
      <v-list dense nav>
        <v-subheader>Kaarten</v-subheader>
        <v-list-item-group color="blue-grey darken-3">
          <v-list-item to="/gebouwen">
            <v-list-item-icon>
              <v-icon>mdi-home-city</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Bouwjaar gebouwen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/gewassen">
            <v-list-item-icon>
              <v-icon>mdi-corn</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Gewassen
                <!-- <v-chip color="primary" class="ml-2" x-small>Update!</v-chip> -->
              </v-list-item-title>
              <!-- <span class="grey--text caption">Peildatum: mei 2023</span> -->
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/kastelen">
            <v-list-item-icon>
              <v-icon>mdi-castle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Kastelen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/molens">
            <v-list-item-icon>
              <v-icon>mdi-fan</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Molens</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group color="blue-grey darken-3" :value="false" prepend-icon="mdi-trash-can">
            <template v-slot:activator>
              <v-list-item-title>Oude stortplaaten</v-list-item-title>
            </template>

            <v-list-item to="/stortplaatsen/drenthe">
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Drenthe</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/stortplaatsen/gelderland">
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Gelderland</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/stortplaatsen/groningen">
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Groningen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/stortplaatsen/limburg">
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Limburg</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/stortplaatsen/noord-brabant">
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Noord-brabant</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/stortplaatsen/noord-holland">
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Noord-holland</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/stortplaatsen/overijssel">
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Overijssel</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/stortplaatsen/zeeland">
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Zeeland</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/stortplaatsen/zuid-holland">
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Zuid-holland</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item to="/speeltoestellen">
            <v-list-item-icon>
              <v-icon>mdi-seesaw</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Speeltoestellen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/verboden-zoekgebieden">
            <v-list-item-icon>
              <v-icon>mdi-cancel</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Verboden zoekgebieden</v-list-item-title>
            </v-list-item-content>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon @click.prevent="togglePinRestrictedAreas">
                  <v-icon v-if="pinRestrictedAreas" color="red">mdi-pin-off</v-icon>
                  <v-icon v-on="on" v-bind="attrs" v-else>mdi-pin</v-icon>
                </v-list-item-icon>
              </template>
              <span>Zet verboden zoekgebieden vast op de kaart</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item to="/verdwenen-nederzettingen">
            <v-list-item-icon>
              <v-icon>mdi-city-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Verdwenen nederzettingen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list dense nav>
        <!-- <v-subheader>Instagram</v-subheader> -->
        <v-list-item>
          <v-list-item-content>
            <p class="text-caption grey--text text--darken-1 mb-2">
              Volg piepkaart op Instagram voor de laatste updates!
            </p>
            <v-btn outlined href="https://www.instagram.com/piepkaart.nl/" target="_blank">
              <v-icon class="mr-2">mdi-instagram</v-icon> piepkaart.nl
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item>
          <v-list-item-content class="text-caption grey--text text--lighten2">
            Het kost tijd en geld om piepkaart.nl online te houden. Door ons met
            een klein bedrag te steunen zorg jij ervoor dat piepkaart.nl kan
            blijven bestaan.
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <ko-fi id="I2I75WYC1" text="Steun piepkaart"></ko-fi>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="blue-grey darken-4" dark clipped-left app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <div style="padding-left: 6px">
        <img src="./assets/logo.svg" alt="Piepkaart Logo" height="28" style="vertical-align: middle" />
      </div>

      <v-spacer></v-spacer>
      <!-- <v-btn href="https://shop.piepkaart.nl" color="deep-orange accent-2">
        Webshop
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import KoFi from "./components/KoFi.vue";
export default {
  name: "App",
  components: {
    // KoFi
  },
  data() {
    return {
      drawer: null,
    };
  },
  methods: {
    togglePinRestrictedAreas() {
      this.$store.commit("pinRestrictedAreas", !this.pinRestrictedAreas);
    },
  },
  computed: {
    showRestrictedAreas() {
      return this.$store.state.showRestrictedAreas;
    },
    pinRestrictedAreas() {
      return this.$store.state.pinRestrictedAreas;
    },
  },
};
</script>
