import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "Gewassen" }
  },
  {
    path: "/gebouwen",
    name: "Gebouwen",
    component: () =>
      import(/* webpackChunkName: "gebouwen" */ "../views/Gebouwen.vue"),
  },
  {
    path: "/molens",
    name: "Molens",
    component: () =>
      import(/* webpackChunkName: "molens" */ "../views/Molens.vue"),
  },
  {
    path: "/kastelen",
    name: "Kastelen",
    component: () =>
      import(/* webpackChunkName: "kastelen" */ "../views/Kastelen.vue"),
  },
  {
    path: "/gewassen",
    name: "Gewassen",
    component: () =>
      import(/* webpackChunkName: "gewassen" */ "../views/Gewassen.vue"),
  },
  {
    path: "/stortplaatsen/drenthe",
    name: "Stortplaatsen Drenthe",
    component: () =>
      import(
        /* webpackChunkName: "stortplaatsen-drenthe" */ "../views/stortplaatsen/Drenthe.vue"
      ),
  },
  {
    path: "/stortplaatsen/gelderland",
    name: "Stortplaatsen Gelderland",
    component: () =>
      import(
        /* webpackChunkName: "stortplaatsen-gelderland" */ "../views/stortplaatsen/Gelderland.vue"
      ),
  },
  {
    path: "/stortplaatsen/groningen",
    name: "Stortplaatsen Groningen",
    component: () =>
      import(
        /* webpackChunkName: "stortplaatsen-groningen" */ "../views/stortplaatsen/Groningen.vue"
      ),
  },
  {
    path: "/stortplaatsen/limburg",
    name: "Stortplaatsen Limburg",
    component: () =>
      import(
        /* webpackChunkName: "stortplaatsen-limburg" */ "../views/stortplaatsen/Limburg.vue"
      ),
  },
  {
    path: "/stortplaatsen/noord-holland",
    name: "Stortplaatsen Noord-Holland",
    component: () =>
      import(
        /* webpackChunkName: "stortplaatsen-noord-holland" */ "../views/stortplaatsen/Noord-Holland.vue"
      ),
  },
  {
    path: "/stortplaatsen/zeeland",
    name: "Stortplaatsen Zeeland",
    component: () =>
      import(
        /* webpackChunkName: "stortplaatsen-zeeland" */ "../views/stortplaatsen/Zeeland.vue"
      ),
  },
  {
    path: "/stortplaatsen/overijssel",
    name: "Stortplaatsen Overijssel",
    component: () =>
      import(
        /* webpackChunkName: "stortplaatsen-overijssel" */ "../views/stortplaatsen/Overijssel.vue"
      ),
  },

  {
    path: "/stortplaatsen/noord-brabant",
    name: "Stortplaatsen Noord-Brabant",
    component: () =>
      import(
        /* webpackChunkName: "stortplaatsen-noord-brabant" */ "../views/stortplaatsen/Noord-Brabant.vue"
      ),
  },
  {
    path: "/stortplaatsen/zuid-holland",
    name: "Stortplaatsen Zuid-Holland",
    component: () =>
      import(
        /* webpackChunkName: "stortplaatsen-zuid-holland" */ "../views/stortplaatsen/Zuid-Holland.vue"
      ),
  },
  {
    path: "/verboden-zoekgebieden",
    name: "Verboden zoekgebieden",
    component: () =>
      import(
        /* webpackChunkName: "verboden-zoekgebieden" */ "../views/VerbodenZoekgebieden.vue"
      ),
  },
  {
    path: "/speeltoestellen",
    name: "Speeltoestellen",
    component: () =>
      import(
        /* webpackChunkName: "speeltoestellen" */ "../views/Speeltoestellen.vue"
      ),
  },
  {
    path: "/verdwenen-nederzettingen",
    name: "Verdwenen Nederzettingen",
    component: () =>
      import(
        /* webpackChunkName: "verdwenen-nederzettingen" */ "../views/VerdwenenNederzettingen.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
